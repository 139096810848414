import { NextPage, NextPageContext } from "next";

import {
  FeedItemResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { UserProfileResponse } from "@every.org/common/src/routes/users";

import NonprofitsPage from "src/pages/Nonprofit";
import { getNonprofitOrUserInitialProps } from "src/utilities/ssr/getNonprofitOrUserInitialProps";
import { getNonprofitPageInitialProps } from "src/utility/apiClient/nonprofitPage";

type UsernameOrNonprofitPageProps = {
  slug: string;
  userInfo?: UserProfileResponse;
  feedItems?: FeedItemResponse[];
  feedHasMore?: boolean;
  initialFundraisers?: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
};

export const getServerSideProps = async (ctx: NextPageContext) => {
  const props = await getNonprofitOrUserInitialProps(
    getNonprofitPageInitialProps
  )(ctx);

  return { props };
};

const UsernameOrNonprofitPage: NextPage<UsernameOrNonprofitPageProps> = ({
  slug,
  feedItems,
  feedHasMore,
  initialFundraisers,
}) => {
  return (
    <NonprofitsPage
      slug={slug}
      contributorList={feedItems}
      contributorListHasMore={feedHasMore}
      initialFundraisers={initialFundraisers}
    />
  );
};

export default UsernameOrNonprofitPage;
